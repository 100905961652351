export function getParams(form) {
  const { elements } = form
  const params = {}

  Array.prototype.forEach.call(elements, element => {
    const { name, value, type, checked } = element

    if (type === "radio") {
      if (checked) Object.assign(params, { [name]: value })
      return
    }

    if (name) Object.assign(params, { [name]: value })
  })

  return params
}
